import React, { useState, Fragment } from 'react'
import { Hr, Spacing } from '../ComponentExport'
import { GiHamburgerMenu, GiCancel } from 'react-icons/gi';
import { HiOutlineMail, HiOutlineLocationMarker } from 'react-icons/hi';
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineUserAdd } from 'react-icons/ai';


import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'

import Logo from '../common/Logo';
import { NavLink, Link } from 'react-router-dom';

const Header = () => {
    const menuLinks = [
        { title: 'ANASAYFA', url: '/' },
        { title: 'KULÜPLER', url: '/kulupler' },
        { title: 'STÜDYO DERS SAATLERİ', url: '/studyo-ders-saatleri' },
        // { title: 'GALERİ', url: '/galeri' },
        { title: 'BLOG', url: '/blog' },
        { title: 'S.S.S', url: '/sss' },
        { title: 'İ.K', url: 'insan-kaynaklari' },
        { title: 'İLETİŞİM', url: '/iletisim' },
    ]
    const [menuView, setMenuView] = useState(false);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return (
        <header className="bg-primary">
            <div className='max-sm:hidden flex items-center py-2'>
                <div className='mx-3  flex items-center'>
                    <HiOutlineMail className='mr-2 text-white' />
                    <span className='text-white'>info@gymfactory.com.tr</span>
                </div>
                {/* <Hr height={15} />
                <div className='mx-3 flex items-center'>
                    <BsTelephone className='mr-2 text-white' />
                    <span className='text-white'>0242 229 30 04</span>
                </div>
                <Hr height={15} />
                <div className='mx-3  flex items-center'>
                    <HiOutlineLocationMarker className='mr-2 text-white' />
                    <span className='text-white'>Uncalı Mah. 1254 Sokak Çayırlı Hasan Sitesi B Blok No 1A 07070 Antalya</span>
                </div> */}
            </div>
            <nav className="mx-auto flex items-center justify-between px-4 border-t-[1px] border-white/30" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">GYM FACTORY</span>
                        <Logo white />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <GiHamburgerMenu className='text-white' />
                        <span className="sr-only">Menü</span>
                    </button>
                </div>
                <div className='h-16 w-[1px] mr-2 bg-white/50 max-sm:hidden'></div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                    {menuLinks.map((val, key) => {
                        return (
                            <NavLink to={val.url} className={({ isActive }) =>
                                `text-sm font-semibold leading-6  duration-300 ${isActive ? 'text-black' : 'text-white'}`
                            }>
                                {val.title}
                            </NavLink>
                        )
                    })}
                    <Link to="https://gymfactory-memberpos.lapiswellness.com/subeBilgileri" target='_blank' className={`text-sm flex font-semibold leading-6 text-white hover:text-black duration-300`}>
                        <AiOutlineUserAdd className='mr-3' size={20} />
                        <span>Üye Ol</span>
                    </Link>
                </Popover.Group>
            </nav>
            <Dialog as="div" className="lg:hidden z-[300]" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <Logo />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <GiCancel />
                            <span className="sr-only">Close menu</span>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6 flex flex-col items-center">
                                {menuLinks.map((val, key) => {
                                    return (
                                        <NavLink to={val.url} onClick={() => setMobileMenuOpen(false)} className={({ isActive }) =>
                                            `text-base font-semibold leading-6  duration-300 ${isActive ? 'text-primary' : 'text-black'}`
                                        }>
                                            {val.title}
                                        </NavLink>
                                    )
                                })}
                                <a
                                    href="https://gymfactory-memberpos.lapiswellness.com/subeBilgileri"
                                    target='_blank'
                                    className="-mx-3 flex items-center rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    <AiOutlineUserAdd className='mr-3' size={20} />
                                    <span>Üye Ol</span>
                                </a>
                            </div>
                            <div className="py-6"></div>
                        </div>
                        <div className='py-2'>
                            <div className='mx-3  flex items-center'>
                                <HiOutlineMail className='mr-2 text-black' />
                                <span className='text-black'>info@gymfactory.com.tr</span>
                            </div>
                            {/* <Hr height={15} />
                            <div className='mx-3 flex items-center'>
                                <BsTelephone className='mr-2 text-black' />
                                <span className='text-black'>0242 229 30 04</span>
                            </div>
                            <Hr height={15} />
                            <div className='mx-3  flex items-center'>
                                <HiOutlineLocationMarker size={30} className='mr-2 text-black' />
                                <span className='text-black'>Uncalı Mah. 1254 Sokak Çayırlı Hasan Sitesi B Blok No 1A 07070 Antalya</span>
                            </div> */}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default Header
import React, { useState, useEffect } from 'react';
import { H3Text, Spacing } from './ComponentExport';
import Logo from './common/Logo';
import AxiosHelper from '../services/AxiosHelper';
import ProgramLogo from '../assets/image/program-logo.png'
import Program from './Program';
const GymProgram = () => {
  const api = AxiosHelper();

  const [branches, setBranches] = useState();
  const [viewProgram, setViewProgram] = useState(0);
  const [selectedBranchName, setselectedBranchName] = useState();
  const [programData, setProgramData] = useState([]);

  const getProgram = (id, name) => {
    setViewProgram(id)
    setselectedBranchName(name)
    api.get(`?table_name=dersler&param_name=branch_id&branch_id=${id}`).then((res) => {
      setProgramData(res)
    })
  }

  useEffect(() => {
    api.get('?table_name=branches').then((res) => {
      setBranches(res);
      getProgram(0);
      setTimeout(() => {
        document.querySelectorAll("#program button")[0].click();
      }, 400);
    })
  }, [])

  return (
    <div className='text-white'>
      <div className='flex justify-center' id="program">
        {branches && branches.map((val) => {
          return (
            <button
              key={val.id}
              onClick={() => getProgram(val.id, val.branch_name)}
              className={`${viewProgram === val.id && 'bg-primary'} rounded-[20px] hover:bg-primary/80 p-2 font-medium border-[1px] border-stone-300`}
            >
              {val.branch_name}
            </button>
          );
        })}
      </div>
      <div>
        {programData && <Program data={programData} name={selectedBranchName}/>}
      </div>
    </div>
  );
}

export default GymProgram;

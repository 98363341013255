import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Outlet, useNavigate, useLocation } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import './index.css'
import Home from "./pages/Home";
import HomePageLayout from "./component/layout/HomePageLayout";
import BlogDetail from "./component/BlogDetail";
import SSS from "./pages/SSS";
import Clubs from "./pages/Clubs";
import Studio from "./pages/Studio";
import IK from "./pages/IK";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import ServicesDetail from "./component/ServicesDetail";
import BlogPage from "./pages/BlogPage";
import ScrollToTop from "./helpers/ScrollToTop";

export default function App() {
  return (
    <BrowserRouter>
      <main className='w-full'>
        <ScrollToTop/>
        <Routes>
          <Route element={<HomePageLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/sss" element={<SSS />} />
            <Route path="/hizmet/:slug" element={<ServicesDetail />} />
            <Route path="/kulupler" element={<Clubs />} />
            <Route path="/studyo-ders-saatleri" element={<Studio />} />
            <Route path="/insan-kaynaklari" element={<IK />} />
            {/* <Route path="/galeri" element={<Gallery />} /> */}
            <Route path="/iletisim" element={<Contact />} />
          </Route>
          {/* 404 */}
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </main>
    </BrowserRouter >
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
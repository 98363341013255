import React from 'react'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacing, Hr } from './ComponentExport';
import { motion } from 'framer-motion';

const Band = () => {
    const bandItems = ['PLANK', 'CARDIO', 'BENCH PRESS', 'DEAD LIFT', 'PILATES', 'DUMBELL']

    return (
        <div className='max-w-full max-sm:hidden z-[9999]'>
            <motion.div
                // initial={{ rotate: 90, opacity:0, scale: 2 }}
                // whileInView={{ rotate: 0, opacity:1, scale: 1 }}
                // transition={{ delay: 0.400, duration: 1 }}
                className='z-50 relative'>
                <Spacing mh={3}>
                    <Hr width='100%' height={4} color='#FF4713' />
                </Spacing>
                <div className='flex justify-center bg-primary'>
                    {bandItems.map((val, key) => {
                        return (
                            <div className='flex items-center'>
                                <span className='text-4xl'>{val}</span>
                                <Spacing mv={10} />
                                <FontAwesomeIcon className='mr-2 text-black' icon={faStar} />
                                <Spacing mv={10} />
                            </div>
                        )
                    })}
                </div>
                <Spacing mh={3} mv={0}>
                    <Hr width='100%' height={4} color='#FF4713' />
                </Spacing>
            </motion.div>
            <motion.div
                // initial={{ rotate: -90, opacity:0, scale: 2 }}
                // whileInView={{ rotate: 6, opacity:1,  scale: 1 }}
                // transition={{ delay: 0.400, duration: 1 }}
                className='z-30 rotate-6'>
                <Spacing mh={3}>
                    <Hr width='100%' height={4} color='#272727' />
                </Spacing>
                <div className='flex justify-center' style={{ background: '#272727' }}>
                    {bandItems.map((val, key) => {
                        return (
                            <div className='flex items-center' style={{ background: '#272727' }}>
                                <span className='text-4xl max-sm:text-sm'>{val}</span>
                                <Spacing mv={10} />
                                <FontAwesomeIcon className='mr-2 text-black' icon={faStar} />
                                <Spacing mv={10} />
                            </div>
                        )
                    })}
                </div>
                <Spacing mh={3} mv={0}>
                    <Hr width='100%' height={4} color='#272727' />
                </Spacing>
            </motion.div>
        </div >
    )
}

export default Band
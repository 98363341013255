import React from 'react'
import { Spacing } from '../ComponentExport'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Elips from '../common/Elips'
import Logo from '../common/Logo'
import { BiLogoFacebook, BiLogoInstagram, BiLogoYoutube } from "react-icons/bi";
import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <footer className='mt-12 mb-4'>
            <div className='flex max-sm:block justify-around p-8 relative'>
                <div>
                    <Logo />
                    <div className='flex flex-col font-medium'>
                        <span className='text-white text-2xl'>SAĞLIKLA</span>
                        <span className='text-white text-2xl'>KALMAK İÇİN</span>
                        <span className='text-primary text-2xl'>GÜCÜ HİSSET</span>
                    </div>
                </div>

                <Elips style={{ right: 0, left: 0, bottom: 0 }} />
                <div className='border-r-1 border-stone-700/50' />
                <div className='max-sm:mt-8'/>
                <div>
                    <span className='text-grey font-medium'>Sağlıklı kalmak için  “SPOR” merkezi</span>
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold'>İLETİŞİMDE KAL</span>
                        <span className='text-white'>0242 259 06 06</span>
                    </div>
                    <Spacing mh={20} />
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold'>MERKEZ KULÜP</span>
                        <span className='text-white'>Uncalı Mah. 1254 Sokak Çayırlı Hasan Sitesi B Blok No 1A 07070</span>
                    </div>
                </div>

                <div className='border-r-1 border-stone-700/50' />
                <div className='max-sm:mt-8'>
                    <span className='text-white font-bold text-2xl'>SİTE HARİTASI</span>
                    <ul>
                        <li>
                            <Link className='text-grey font-medium' to="/">ANASAYFA</Link>
                        </li>
                        <li>
                            <Link className='text-grey font-medium' to="/kulupler#">KULÜPLER</Link>
                        </li>
                        <li>
                            <Link className='text-grey font-medium' to="/studyo-ders-saatleri#">STÜDYO DERS SAATLERİ</Link>
                        </li>
                        <li>
                            <Link className='text-grey font-medium' to="/blog#">BLOG</Link>
                        </li>
                        <li>
                            <Link className='text-grey font-medium' to="/sss#">SSS</Link>
                        </li>
                        <li>
                            <Link className='text-grey font-medium' to="/insan-kaynaklari#">İK</Link>
                        </li>
                        <li>
                            <Link className='text-grey font-medium' to="/iletisim#">İLETİŞİM</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='border-b-1 border-stone-700/50' />

            <div className='flex max-sm:text-center max-sm:block items-center justify-between px-28 max-sm:px-0 mt-4'>
                <div className='text-white'>
                    © 2023, <span className='text-primary font-medium'>BECRATIVE,</span> All rights reserved
                </div>
                <div className='flex justify-center'>
                <button
                  onClick={() => window.open('https://www.instagram.com/gymfactorytr/', '_blank')}
                >
                    <BiLogoInstagram className='bg-white/20 hover:bg-primary duration-300 text-white p-3 m-2' size={50} />
                </button>
                <button
                  onClick={() => window.open('https://www.facebook.com/gymfactorytr/', '_blank')}
                >
                    <BiLogoFacebook className='bg-white/20 hover:bg-primary duration-300 text-white p-3 m-2' size={50} />
                </button>
                <button
                  onClick={() => window.open('https://www.youtube.com/@GymFactorytr/', '_blank')}
                >
                    <BiLogoYoutube className='bg-white/20 hover:bg-primary duration-300 text-white p-3 m-2' size={50} />
                </button>
                </div>
            </div>
        </footer>
    )
}

export default Footer
import React, { useEffect, useState } from 'react'
import { faLeftLong, faP, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button1, Spacing } from './ComponentExport';
import Image1 from '../assets/image/image-1.png'
import Serit from '../assets/image/serit.png'
import SparklesCanvas from './Sparkles';
import Smoke from './Smoke';
import FogEffect from './Smoke';
import FogImg from '../assets/image/fog.png';
import Elips from './common/Elips';
import { motion, useScroll } from 'framer-motion';
import AxiosHelper from '../services/AxiosHelper';

const Statistics = () => {
  const api = AxiosHelper();

  const { scrollYProgress } = useScroll();

  const [statisticsData, setStatisticsData] = useState()
  const getStatistics = () => {
    api.get(`?table_name=	info_cards`).then((res) => {
      setStatisticsData(res)
    })
  }

  useEffect(() => {
    getStatistics();
  }, [])

  return (
    <div className='max-sm:px-0 max-sm:py-0 px-40 py-36 relative'>
      <div className='-z-10'>
        <div className='absolute right-0 top-0 -z-10'>
          <SparklesCanvas />
        </div>
        <div>
          <img className='absolute -top-10 right-32 blur-sm h-3/5 opacity-20 -z-10' src={FogImg} />
          <Elips style={{ right: 0, bottom: 10, }} />
        </div>
      </div>
      <motion.div
        // initial={{ opacity: 0, translateY: -300 }}
        // whileInView={{ opacity: 1, translateY: 0 }}
        // exit={{ opacity: 0, translateY: -300 }}
        className='flex max-sm:block justify-between z-10'>
        {console.log(scrollYProgress)}
        <div className='max-sm:text-center'>
          <div className='flex flex-col text-4xl text-white font-medium max-sm:text-2xl'>
            <span>AKDENİZ'İN</span>
            <span>EN BÜYÜK</span>
            <span>SPOR MERKEZİ</span>
          </div>
          <div className='max-sm:hidden'><Spacing mh={35} /></div>
          <div className='w-80 max-sm:w-full max-sm:p-8'>
            <div className='flex flex-col text-grey font-medium text-left max-sm:text-center'>
              <span>Konyaalti şube içinde 2.200m2</span>
              <span>Isparta şube içinde 4.200m2 </span>
              <span>Kadınlara özel 1.500m2 spor alan</span>
            </div>
          </div>
          <Spacing mh={35} />
          <div className='max-sm:flex max-sm:justify-center'>
            
          <Button1 to="https://gymfactory-memberpos.lapiswellness.com/subeBilgileri" target title='HEMEN ÜYE OL' icon={faPlus} />
          </div>
        </div>
        <div className='-z-10'>
          <img className='max-sm:relative absolute max-sm:scale-100 scale-150 left-2/4 transform -translate-x-2/4' src={Image1} />
        </div>
        <div className="flex flex-col items-center">
          {statisticsData && statisticsData.map((val, key) => {
            return (
              val.title && <motion.div
                initial={{ opacity: 0, translateY: -300 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ delay: 0.500, duration: 1.5 }}
              >
                <Spacing mh={25} />
                <div className='bg-zinc-700/95 border-b-8 border-primary rounded-3xl w-72 h-36 flex flex-col justify-center p-4 hover:scale-110 duration-300'>
                  <div className='flex'>
                    <img style={{ height: 20, width: 50 }} src={Serit} />
                    <span className='text-white font-bold'>{val.title}</span>
                  </div>
                  <span className='text-white text-3xl'>{val.number}+</span>
                </div>
              </motion.div>
            )
          })}
        </div>
      </motion.div >
    </div>
  )
}

export default Statistics
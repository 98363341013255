import React from 'react'
import RegisterIK from '../component/RegisterIK'
import { Helmet } from "react-helmet";

const IK = () => {
  return (
    <div>
      <Helmet>
        <title>İnsan Kaynakları</title>
        <meta name="description" content="Gym Factory" />
        <meta name="keywords" content="" />
      </Helmet>
      <RegisterIK />
    </div>
  )
}

export default IK
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import AxiosHelper from '../services/AxiosHelper';
import Elips from '../component/common/Elips';
import { Helmet } from "react-helmet";

const BlogPage = () => {

    const api = AxiosHelper();
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        api.get('?table_name=blog_posts').then((res) => {
            setBlog(res);
        })
    }, []);

    return (
        <div className='py-8 px-32 max-sm:px-0'>
            <Helmet>
                <title>Blog</title>
                <meta name="description" content="Gym Factory" />
                <meta name="keywords" content="" />
            </Helmet>
            <div className='text-center'>
                <h3 className='text-white text-3xl max-sm:scale-75 outlined-text-grey'>BLOG</h3>
                <h3 className='text-white text-6xl max-sm:text-3xl'>BİZDEN HABERLER</h3>
            </div>
            <div className='flex justify-center mt-8'>
                <Elips style={{ left: 10 }} />
                <div className="grid grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-6 justify-between">
                    {
                        blog.map((data) => {
                            return (
                                <Link to={`/blog/${data.slug}`}><div className='max-w-lg relative'>
                                    <div className='bg-primary rotate-90 px-4 top-16 -right-8 absolute'>
                                        <span className='text-white'>{data.date}</span>
                                    </div>
                                    <img src={`https://batuhankaan.com/musteri/gym-factory//${data.cover_photo}`} />
                                    <div className='border-1 border-stone-700/50 border-t-0'>
                                        <div className='text-white p-3'>{data.title}</div>
                                    </div>
                                </div>
                                </Link>
                            )
                        })
                    }
                </div>

                <Elips style={{ right: 10 }} />
            </div>

        </div>
    )
}

export default BlogPage
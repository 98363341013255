import React from 'react';

const Spacing = ({ mh, mv, children }) => {
  const style = {
    margin: mh && mv ? `${mv}px ${mh}px` : mh ? `${mh}px 0` : mv ? `0 ${mv}px` : 0,
  };

  return (
    <div style={style}>
      {children}
    </div>
  );
};

export default Spacing;

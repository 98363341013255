import React from 'react'

const Button2 = ({title, onClick}) => {
    return (
        <button onClick={onClick} className='bg-primary p-6 px-10 rounded-[20px]'>
            <span className='text-white'>{title}</span>
        </button>
    )
}

export default Button2
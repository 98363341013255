import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Button1 = ({ title, icon, to, target }) => {
    return (
        <Link to={to} target={target && '_blank'} >
            <div className='bg-primary w-64 hover:bg-primary/5 hover:scale-105 ease-in-out duration-500'>
                <div className='bg-zinc-700/90 mx-1 p-5 flex justify-around items-center'>
                    <span className='text-white'>{title}</span>
                    {icon && <FontAwesomeIcon className='mr-2 text-white' icon={icon} />}
                </div>
            </div>
        </Link>
    )
}

export default Button1
import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { Spacing } from '../ComponentExport'
import axios from 'axios'
import WhatsAppButton from '../buttons/WhatsappButton'

const HomePageLayout = ({ children }) => {
    return (
        <>
            <Header />
            <Outlet />
            <Spacing mh={40} />
            
            <WhatsAppButton/>
            <Footer />
        </>
    );
};

export default HomePageLayout;
import { FaWhatsapp } from 'react-icons/fa';
import { useState, useEffect } from 'react';

const WhatsAppButton = () => {
  const [showBalloon, setShowBalloon] = useState(false);

  const handleClick = () => {
    // WhatsApp numaranızı buraya yazın
    window.open('https://api.whatsapp.com/send?phone=+905530696029&text=GYMfactory%20hakk%C4%B1nda%20bilgi%20alabilir%20miyim%20?');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBalloon(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShowBalloon(false);
  };

  return (
    <>
      <div
        className="fixed z-[150] bottom-8 right-12 bg-green-500 hover:bg-green-600 text-white rounded-full w-16 h-16 flex justify-center items-center cursor-pointer shadow-lg transition-all duration-300 transform hover:scale-105"
        onClick={handleClick}
      >
        <FaWhatsapp size={30} />
      </div>
      {showBalloon && (
        <div
          className="fixed z-[150] bottom-28 max-w-fit right-4 mx-4 bg-white text-gray-800 rounded-lg shadow-lg p-4 flex items-center"
          onClick={handleClose}
        >
          <span className="mr-4 text-xl">💬</span>
          <span className="flex-1">Sorularınız için bizimle iletişime geçebilirsiniz.</span>
          <span className="text-xl cursor-pointer">&times;</span>
        </div>
      )}
    </>
  );
};

export default WhatsAppButton;
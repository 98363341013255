import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Elips from './common/Elips';
import AxiosHelper from '../services/AxiosHelper';
import { BsCalendar3 } from "react-icons/bs";
import { Helmet } from "react-helmet";

const BlogDetail = () => {

    const api = AxiosHelper();
    const params = useParams();
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        api.get('?blog_detail=' + params.slug).then((res) => {
            setBlog(res[0]);
        })
    }, []);

    return (
        <>
            <Helmet>
                <title>{blog.title}</title>
                <meta name="description" content="Gym Factory" />
                <meta name="keywords" content={blog.keywords} />
            </Helmet>
            <div className='blog-detail p-4'>
                <div className='py-16 bg-black/60'>
                    {/* <Elips style={{ right: 10, left: 10 }} /> */}
                    <h3 className='text-white text-center text-5xl max-sm:text-3xl'>{blog.title}</h3>
                </div>
                {blog && <div className='w-full px-64 max-sm:px-0 mt-12'>

                    <div className=''>
                        <div className='mb-10'>
                            <img src={`https://batuhankaan.com/musteri/gym-factory//${blog.cover_photo}`} alt='Resim' />
                            <div className='text-white flex items-center mt-4'>
                                <BsCalendar3 className='mr-3' />
                                <span>{blog.date}</span>
                            </div>
                        </div>

                        <div className='text-grey' dangerouslySetInnerHTML={{ __html: blog.content }} />
                    </div>
                </div>}
                <Elips style={{ right: 10 }} />
            </div>
        </>
    )
}

export default BlogDetail
import React, { useRef, useEffect } from 'react';

const SparklesCanvas = () => {
  const canvasRef = useRef();
  const sparkles = [];

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);

    function getRandomGradient() {
      const hue = Math.random() * 20 + 10;
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, `hsl(${hue}, 100%, 50%)`);
      gradient.addColorStop(0.5, `hsl(${hue + 10}, 100%, 50%)`);
      gradient.addColorStop(1, `hsl(${hue}, 100%, 50%)`);
      return gradient;
    }

    class Sparkle {
      constructor() {
        this.x = canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 10 + 2;
        this.color = getRandomGradient();
        this.velocityX = -(Math.random() * 1 + 0.5);
        this.velocityY = (Math.random() - 0.5) * 2;
        this.alpha = 1;
        this.angle = (10 + Math.random() * 20) * (Math.PI / 180); // Rastgele dönüş açısı
        this.shadowBlur = 10;
        this.shadowColor = 'white';
      }

      draw() {
        ctx.save();
        ctx.translate(this.x, this.y);
        const angle = Math.atan2(this.velocityY, this.velocityX); // Hız vektörünün açısını hesapla
        ctx.rotate(angle); // Hız vektörüne göre döndür

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(this.size, 0);
        ctx.strokeStyle = this.color;
        ctx.lineWidth = 2;
        ctx.shadowBlur = this.shadowBlur;
        ctx.shadowColor = this.shadowColor;
        ctx.globalAlpha = this.alpha;
        ctx.stroke();
        ctx.restore();
      }


      update() {
        this.x += this.velocityX;
        this.y += this.velocityY;
        this.alpha -= 0.002;

        if (this.alpha <= 0 || this.size >= 30) {
          this.reset();
        }
      }

      reset() {
        this.x = canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 10 + 2;
        this.color = getRandomGradient();
        this.velocityX = -(Math.random() * 5 + 0.5);
        this.velocityY = (Math.random() - 0.5) * 6;
        this.alpha = 1;
        this.angle = (10 + Math.random() * 20) * (Math.PI / 180); // Rastgele dönüş açısı
        this.shadowBlur = 10;
      }
    }
    const maxSparkles = 100; // Örnek bir sınır sayısı

    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (sparkles.length < maxSparkles && Math.random() < 0.05) {
        sparkles.push(new Sparkle());
      }

      for (let i = 0; i < sparkles.length; i++) {
        sparkles[i].draw();
        sparkles[i].update();
      }
    };

    resizeCanvas();
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} id="sparkles"></canvas>;
};

export default SparklesCanvas;

import React, { useEffect, useState } from 'react'
import AxiosHelper from '../services/AxiosHelper';
import Logo from '../component/common/Logo';
import Serit from '../assets/image/serit.png'
import { FaFacebook, FaGoogle, FaTwitter, FaInstagram, FaPinterest } from "react-icons/fa";
import { Band, HomeBlogPost, InstagramPosts, Packets, RegisterBox, Spacing } from '../component/ComponentExport';
import Program from '../component/Program';
import { Helmet } from "react-helmet";

const Clubs = () => {
    const api = AxiosHelper();

    const [branches, setBranches] = useState();
    const [branchDetail, setBranchDetail] = useState();
    const [activeBranches, setActiveBranches] = useState();
    const [program, setProgram] = useState();
    useEffect(() => {
        api.get('?table_name=branches').then((res) => {
            setBranches(res);
            setTimeout(() => {
                document.querySelectorAll("#clubs button")[0].click();
            }, 300);
        })
    }, [])

    const getBranchDetail = (id) => {
        setActiveBranches(id)
        api.get(`api.php?branch_detail=${id}`).then((res) => {
            setBranchDetail(res)
            setProgram(res.schedule);
        })
    }

    return (
        <div class="">
            <Helmet>
                <title>Stüdyo Ders Saatleri</title>
                <meta name="description" content="Gym Factory" />
                <meta name="keywords" content="" />
            </Helmet>
            <div id="clubs" className='flex justify-center my-3'>
                {branches && branches.map((val) => {
                    return (
                        <button
                            key={val.id}
                            onClick={() => getBranchDetail(val.id)}
                            className={`${activeBranches === val.id ? 'bg-primary text-white' : 'bg-white'} rounded-[20px] hover:bg-primary/90 p-2 font-medium border-stone-300`}
                        >
                            {val.branch_name}
                        </button>
                    );
                })}
            </div>

            {branchDetail && <Program data={program} name={branchDetail.branch.branch_name} hideLogos />}
            <Spacing mh={20} />
            <Band />
            <RegisterBox />
            {/* <InstagramPosts /> */}
            {/* {branchDetail && <Packets data={branchDetail.packages} />} */}
            {branchDetail && <Packets/>}

            <HomeBlogPost />
        </div>
    )
}

export default Clubs
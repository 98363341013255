import React, { useEffect, useState } from 'react'
import { Button2, Spacing } from './ComponentExport'
import SparklesCanvas from './Sparkles'
import Logo from './common/Logo'
import AxiosHelper from '../services/AxiosHelper'

const RegisterBox = () => {
    const api = AxiosHelper();


    const [isim, setIsim] = useState();
    const [soyisim, setSoyisim] = useState();
    const [yas, setYas] = useState();
    const [cinsiyet, setCinsiyet] = useState();
    const [kilo, setKilo] = useState();
    const [boy, setBoy] = useState();
    const [branchId, setBranchId] = useState();

    const [branches, setBranches] = useState();
    useEffect(() => {
        api.get('?table_name=branches').then((res) => {
            setBranches(res);
            //   setTimeout(() => {
            //     document.querySelectorAll("#program button")[0].click();
            //   }, 300);
        })
    }, [])


const [responseMessage, setResponseMessage] = useState();
    const RegisterAction = () => {
        api.post('api.php', {isim, soyisim, yas, cinsiyet, kilo, boy, branch_id:branchId}).then((res) => {
            setResponseMessage(res)
            if(res.status){
                setIsim('');
                setSoyisim('');
                setYas('');
                setCinsiyet('');
                setKilo('');
                setBoy('');
                setBranchId('');
            }
        })
    }

    return (
        <div className='py-40 mb-32 max-sm:py-16 max-sm:mb-12 bg-stone-900/60 relative'>
            {/* <div className='absolute -z-10 right-0 top-0'>
                <SparklesCanvas />
            </div> */}
            <div className='flex items-center flex-col'>
                <Logo />
                <h3 className='text-white text-center text-4xl mb-12'>HEMEN ÜYE OL</h3>
            </div>

            <div className='px-24 max-sm:px-8 z-50'>
                <div className="grid grid-cols-6 max-sm:grid-cols-1 grid-rows-1 gap-4">
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold ml-1'>İSİM</span>
                        <input required onChange={(e) => setIsim(e.currentTarget.value)} value={isim} className='placeholder:text-white bg-white/50 p-3 rounded-[20px] h-12' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold ml-1'>SOYİSİM</span>
                        <input required onChange={(e) => setSoyisim(e.currentTarget.value)} value={soyisim} className='placeholder:text-white bg-white/50 p-3 rounded-[20px] h-12' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold ml-1'>YAŞ</span>
                        <input required onChange={(e) => setYas(e.currentTarget.value)} value={yas} className='placeholder:text-white bg-white/50 p-3 rounded-[20px] h-12' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold ml-1'>CİNSİYET</span>
                        <select required onChange={(e) => setCinsiyet(e.currentTarget.value)} value={cinsiyet} className='placeholder:text-white bg-white/50 p-3 rounded-[20px] h-12' >
                            <option></option>
                            <option>Erkek</option>
                            <option>Kadın</option>
                            <option>Belirtmek istemiyorum</option>
                        </select>
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold ml-1'>KİLO</span>
                        <input required onChange={(e) => setKilo(e.currentTarget.value)} value={kilo} className='placeholder:text-white bg-white/50 p-3 rounded-[20px] h-12' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold ml-1'>BOY</span>
                        <input required onChange={(e) => setBoy(e.currentTarget.value)} value={boy} className='placeholder:text-white bg-white/50 p-3 rounded-[20px] h-12' />
                    </div>
                </div>
                <Spacing mh={25} />
                <div className='grid grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-4'>
                    <div className='flex flex-col'>
                        <span className='text-primary font-bold ml-1'>ŞUBE</span>
                        <select required onChange={(e) => setBranchId(e.currentTarget.value)} value={branchId} className='placeholder:text-white bg-white/50 p-3 rounded-[20px] h-12' >
                            <option></option>
                            {branches && branches.map((val, key) => {
                                return (
                                    <option value={val.id}>{val.branch_name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <Button2 title='GÖNDER' onClick={() => RegisterAction()} />
                </div>
                {responseMessage && 
                <div className='text-white'>
                    {responseMessage.message}
                </div>
                }
            </div>

        </div>
    )
}

export default RegisterBox
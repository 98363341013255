import React, { useState, useEffect } from 'react';
import AxiosHelper from '../services/AxiosHelper';
import useWindowSize from '../helpers/WindowSize';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

const Services = () => {
  const windowSize = useWindowSize();
  const api = AxiosHelper();
  const [service, setService] = useState([]);

  useEffect(() => {
    api.get('?table_name=services').then((res) => {
      setService(res);
    });
  }, []);

  const slidesPerView = windowSize.width > 768 ? 4 : 1;

  return (
    <div className='m-auto services'>
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={15}
        slidesPerView={slidesPerView}
        pagination={{ clickable: true }}
        loop
        autoplay={{ delay: 1500 }}
      >
        {service &&
          service.map((val, key) => (
            <SwiperSlide key={key}>
              <div className='relative'>
                <Link to={`/hizmet/${val.slug}`}>
                  {/* <span
                    className='absolute text-2xl text-white'
                    style={{ top: '20%', left: '70px', transform: 'translate(-50%, -50%)' }}
                  >
                    {val.title.split(' ').map((word, index) => (
                      <span key={index} style={{ display: 'block' }}>
                        {word}
                      </span>
                    ))}
                  </span> */}
                  <div className='min-w-min'>
                    <img className='object-cover rounded-[20px]' src={`https://batuhankaan.com/musteri/gym-factory//${val.image}`} alt={val.title} />
                  </div>
                  {/* <span className='absolute z-10 bottom-5 left-4 text-6xl outlined-text'>
                    {key < 10 ? `0${key + 1}` : key + 1}
                  </span> */}
                </Link>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default Services;

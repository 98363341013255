import React from 'react';
import './Elips.css'; // Elips için stil dosyasını içe aktar

const Elips = ({style}) => {
  return (
    <div className="elips-container absolute -z-10" style={style}>
      <div className="elips"></div>
    </div>
  );
};

export default Elips;

import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import AxiosHelper from '../services/AxiosHelper';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowSize from '../helpers/WindowSize';

const BeSlider = () => {
    const windowSize = useWindowSize();
    const api = AxiosHelper();
    const [images, setImages] = useState([]);

    useEffect(() => {
        api.get('?table_name=slider').then((res) => {
            setImages(res);
        })
    }, []);

    const settings = {
        dots: true,
        arrows:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className=''>
            <Slider {...settings}>
                {images && (
                    images.map((data,key) => {
                        return (
                            <div className="aspect-w-16 aspect-h-9" key={key}>
                                <img
                                    src={`https://batuhankaan.com/musteri/gym-factory//${data.image}`}
                                    className="w-full h-full object-cover" // object-cover sınıfı eklendi
                                />
                            </div>
                        )
                    })
                )}
            </Slider>
        </div>

    )
}

export default BeSlider;

import React, { useEffect, useState } from 'react'
import { Spacing } from './ComponentExport'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GymFactory1 from '../assets/image/gym-factory-1.jpg'
import { IconBaseProps, IconType } from "react-icons/lib";
import AxiosHelper from '../services/AxiosHelper';

const WhyFactory = () => {
  const api = AxiosHelper();
  const [whyGym, setWhyGym] = useState();

  useEffect(() => {
    api.get('?table_name=why_gymfactory').then((res) => {
      setWhyGym(res);
    })
  }, []);

  return (
    <div className='pt-24 max-sm:mb-2 mb-28'>
      <h3 className='text-white text-center text-6xl max-sm:text-2xl font-medium mb-12'>NEDEN GYMFACTORY ?</h3>
      {/* <div className='grid grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-0'> */}
      <div>
        <div className='relative'>
          <a href="https://www.youtube.com/watch?v=5BUBHnNxupg" target='_blank' className='absolute inset-0 flex items-center justify-center'>
            <div class="play-container">
              <div class="play-button">
                <div class="pulse-ring"></div>
                Play
              </div>
            </div>
          </a>
          <img className='h-[500px] max-sm:h-[200px] object-contain m-auto' src={GymFactory1} />
        </div>
        {/* <div className="grid grid-cols-2 max-sm:grid-cols-1 grid-rows-2 gap-0">
          {whyGym && whyGym.map((val, key) => {
            const isOddMobile = key % 2 === 0; // Mobil: Çift sayılar için siyah, tek sayılar için turuncu
            const isOddDesktop = key % 4 === 0 || key % 4 === 3; // Masaüstü: 1 ve 4 siyah, 2 ve 3 turuncu

            return (
              <div key={key} className={`max-sm:${isOddMobile ? "bg-black" : "bg-primary"} ${isOddDesktop ? "bg-black" : "bg-primary"} flex flex-col items-start p-6`}>
                <FontAwesomeIcon size='3x' className='mr-2 text-white' icon={faStar} />
                <Spacing mh={10} />
                <span className='text-2xl text-white'>{val.name}</span>
                <Spacing mh={10} />
                <p className='text-white'>{val.content}</p>
              </div>
            )
          })}

        </div> */}
      </div>
    </div>
  )
}

export default WhyFactory
import { faBars, faComment, faEnvelope, faLocation, faPhone, faPlus, faStar, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import '../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Serit from '../assets/image/serit.png'
import { Hr, Services, Spacing, Statistics, Band, WhyFactory, RegisterBox, GymProgram, Packets, InstagramPosts, HomeBlogPost, BeSlider } from '../component/ComponentExport';
import { Helmet } from "react-helmet";


function Home() {
    return (
        <>
            <Helmet>
                <title>GYM FACTORY</title>
                <meta name="description" content="GYM FACTORY" />
                <meta name="keywords" content={"antalya, gym factory, spor salonu"} />
            </Helmet>
            <BeSlider />
            <Spacing mh={10}>
                <Hr color='#FFFFFF33' width='full' />
            </Spacing>
            <Services />
            <Spacing mh={10}>
                <Hr color='#FFFFFF33' width='full' />
            </Spacing>
            <Statistics />
            <Band />
            <WhyFactory />
            <RegisterBox />
            <GymProgram />
            <Packets />
            {/* <InstagramPosts /> */}
            <HomeBlogPost />
        </>
    );
}

export default Home;

import React from 'react'
import GymFactory from '../../assets/image/logo.svg'
import { Link } from 'react-router-dom';

const Logo = ({ white }) => {
    return (
      <Link to={"/"}>
        <img src={GymFactory} alt="Logo" style={{ filter: white ? 'brightness(0) invert(1)' : 'none' }} />
      </Link>
    );
  };

export default Logo
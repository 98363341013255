import React, { useEffect, useState } from 'react'
import AxiosHelper from '../services/AxiosHelper';
import Serit from '../assets/image/serit.png'
import Elips from '../component/common/Elips';
import Logo from '../component/common/Logo';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Button1 } from '../component/ComponentExport';
import { Helmet } from "react-helmet";

const Contact = () => {

    const api = AxiosHelper();
    const [blog, setBlog] = useState([]);
    const [nameSurname, setNameSurname] = useState();
    const [mail, setmail] = useState();
    const [subject, setSubject] = useState();
    const [content, setContent] = useState();

    useEffect(() => {
        api.get('?table_name=blog_posts').then((res) => {
            setBlog(res);
        })
    }, []);

    const containerStyle = {
        width: '800px',
        height: '400px'
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    const position = {
        lat: -3.745,
        lng: -38.523
    };

    return (
        <div className='flex flex-col justify-center items-center py-8 px-32 max-sm:px-0'>
            <Helmet>
                <title>İletişim</title>
                <meta name="description" content="Gym Factory" />
                <meta name="keywords" content="" />
            </Helmet>
            <div className='flex flex-col items-center'>
                <Logo />
                <h3 className='text-white text-3xl max-sm:text-2xl font-medium'>İletişim</h3>
            </div>
            <Elips style={{ left: 10 }} />

            <div className='flex justify-center mt-8 max-sm:block max-sm:px-8'>
                <div className='border-1 border-grey/40 text-white'>
                    <div className='flex border-b-1 border-grey/40 p-3'>
                        <img src={Serit} />
                        <span>Antalya</span>
                    </div>
                    <div className='p-3'>
                        <div>
                            <span className='font-bold'>Telefon</span>
                            <div>Telefon: 0242 259 06 06</div>
                        </div>
                        <br />
                        <div>
                            <span className='font-bold'>Whatsapp</span>
                            <div>Telefon: 0553 069 60 29</div>
                        </div>
                    </div>
                </div>
                <div className='border-1 border-grey/40 text-white max-sm:mt-4'>
                    <div className='flex max-sm:block border-b-1 border-grey/40 p-3'>
                        <img src={Serit} />
                        <span>Isparta</span>
                    </div>
                    <div className='p-3'>
                        <div>
                            <span className='font-bold'>Telefon</span>
                            <div>Telefon: 0246 227 44 44</div>
                        </div>
                        <br />
                        <div>
                            <span className='font-bold'>Whatsapp</span>
                            <div>Telefon: 0553 069 60 29</div>
                        </div>
                    </div>
                </div>
                <Elips style={{ right: 10 }} />
            </div>

            <div className='mt-8'>
                {/* <div className='bg-red-600 text-white p-4'>
                    <div>2 - Google analytics veya yandex metrica eklenecekse ilgili bilgiler</div>
                    <div>3 - Google ads dönüşüm takibi eklenecekse gtmId</div>
                    <div>4 - Facebook pixel eklenecekse pixel_id</div>
                </div> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12764.39637500206!2d30.6253013!3d36.8879776!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c3918939df5eb3%3A0x193e72e91dddb6a6!2sGymFactory%20Konyaalt%C4%B1!5e0!3m2!1sen!2str!4v1704978089433!5m2!1sen!2str" width="900" height="450" className="border-none" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className='px-10 mt-8'>
                <div className='flex max-sm:block justify-around'>
                    <div className='w-1/4 max-sm:w-full'>
                        <div className='flex flex-col'>
                            <div className='outlined-text-grey'>İLETİŞİM</div>
                            <div className='text-white text-3xl max-sm:text-2xl'>İLETİŞİMDE KALIN</div>
                        </div>
                        <div className='text-grey max-sm:mt-4 mt-20 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse interdum nulla eu posuere scelerisque. Donec sagittis adipiscing elit.</div>
                    </div>
                    <div>
                        <div className='flex max-sm:block'>
                            <div className='flex flex-col mr-8'>
                                <span className='text-primary font-bold ml-1'>İSİM SOYİSİM</span>
                                <input required onChange={(e) => setNameSurname(e.currentTarget.value)} value={nameSurname} className='placeholder:text-white bg-white/50 p-3 rounded-[20px]' />
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-primary font-bold ml-1'>E-POSTA</span>
                                <input required onChange={(e) => setNameSurname(e.currentTarget.value)} value={nameSurname} className='placeholder:text-white bg-white/50 p-3 rounded-[20px]' />
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-primary font-bold ml-1'>KONU</span>
                            <input required onChange={(e) => setNameSurname(e.currentTarget.value)} value={nameSurname} className='placeholder:text-white bg-white/50 p-3 rounded-[20px]' />
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-primary font-bold ml-1'>MESAJ</span>
                            <textarea required onChange={(e) => setNameSurname(e.currentTarget.value)} value={nameSurname} className='placeholder:text-white bg-white/50 p-3 rounded-[20px]' />
                        </div>
                        <div className='mt-4'>
                            <Button1 title='GÖNDER' />
                        </div>
                    </div>
                </div>
            </div>

  
        </div>
    )
}

export default Contact
import React, { useState,useEffect } from "react";
import AxiosHelper from "../services/AxiosHelper";
import Band from "../component/Band";
import { AiFillCaretDown } from "react-icons/ai";
import Elips from "../component/common/Elips";
import { Helmet } from "react-helmet";
const SSS = () => {

    const api = AxiosHelper();
    const [faq, setFaq] = useState([]);

    useEffect(() => {
        api.get('?table_name=questions').then((res) => {
            setFaq(res);
        })
    }, []);
    return (
        <section className="relative overflow-hidden bg-black pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
            <Helmet>
                <title>Sıkça Sorulan Sorular</title>
                <meta name="description" content="Gym Factory" />
                <meta name="keywords" content="" />
            </Helmet>
            <div className="container mx-auto">
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4">
                        <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
                            <span className="mb-2 block text-lg font-semibold text-primary">
                                SSS
                            </span>
                            <h2 className="mb-4 text-3xl font-bold text-white dark:text-white sm:text-[40px]/[48px]">
                              Sıkca Sorulan Sorular
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="-mx-4 flex flex-wrap p-4">
                    {faq && faq.map((val,key) => {
                        return (
                            <div className="w-full px-4 lg:w-1/3">
                                <AccordionItem
                                    header={val.question}
                                    text={val.answer}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
           <div className="mt-32">
           <Band/>
           </div>
        </section>
    );
};

export default SSS;

const AccordionItem = ({ header, text }) => {
    const [active, setActive] = useState(false);

    const handleToggle = (event) => {
        // event.preventDefault();
        setActive(!active);
    };
    return (
        <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8">
            <button
                className={`faq-btn flex w-full text-left`}
                onClick={() => handleToggle()}
            >
                <Elips/>
                <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
                   <AiFillCaretDown className={`fill-primary stroke-primary duration-200 ease-in-out ${active ? "rotate-180" : "" }`} />
                </div>

                <div className="w-full">
                    <h4 className="mt-1 text-lg font-semibold text-dark">
                        {header}
                    </h4>
                </div>
            </button>

            <div className={`pl-[62px] duration-200 ease-in-out ${active ? "block" : "hidden"}`}>
                <p className="py-3 leading-relaxed text-body-color">
                    {text}
                </p>
            </div>
        </div>
    );
};

import React, { useState } from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';
import AxiosHelper from '../services/AxiosHelper';
import Logo from './common/Logo';
import SparklesCanvas from './Sparkles';
import Elips from './common/Elips';
import { Band } from './ComponentExport';

const RegisterIK = () => {
    const api = AxiosHelper();
    const [formData, setFormData] = useState({
        isim: "",
        soyisim: "",
        gsm: "",
        born: "",
        position: "",
        resume: "",
        otherInfo: "",
        cv: ""
    });

    const [responseMessage, setResponseMessage] = useState();
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    }

    const RegisterAction = () => {
        if (!recaptchaValue) {
            alert('Lütfen reCAPTCHA\'yı onaylayın.');
            return;
        }

        api.post('api.php', formData).then((res) => {
            setResponseMessage(res)
            if (res.status) {
                // Başarılı işlem sonrası yapılacak işlemler
            }
        })
    }

    return (
        <div>
            <div className='py-40 px-2 max-sm:py-8 max-sm:px-6 mb-32 max-sm:mb-4 bg-stone-900/60 relative'>
                <div className='absolute -z-10 right-0 top-0'>
                    <SparklesCanvas />
                </div>
                <div className='flex items-center flex-col'>
                    <Logo />
                    <h3 className='text-white text-center text-3xl max-sm:text-2xl font-medium mb-12'>İNSAN KAYNAKLARI</h3>
                </div>
                <Elips style={{ right: 10, left: 10 }} />
                <Elips style={{ right: 10, left: 10, top: 10 }} />
                <div className='px-24 z-50 max-sm:block max-sm:px-2 w-max flex flex-col m-auto'>
                    <div className="flex max-sm:block justify-between">
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>İSİM</span>
                            <input required onChange={handleInputChange} name="isim" value={formData.isim} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>SOYİSİM</span>
                            <input required onChange={handleInputChange} name="soyisim" value={formData.soyisim} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>GSM</span>
                            <input required onChange={handleInputChange} name="gsm" value={formData.gsm} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>DOĞUM TARİHİ</span>
                            <input required onChange={handleInputChange} name="born" value={formData.born} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>ÇALIŞMAK İSTEDİĞİNİZ POZİSYON</span>
                            <input required onChange={handleInputChange} name="position" value={formData.position} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                    </div>
                    <div className='flex max-sm:block justify-between mt-8'>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>KISA ÖZGEÇMİŞ</span>
                            <textarea required onChange={handleInputChange} name="resume" value={formData.resume} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>EKLEMEK İSTEDİĞİNİZ DİĞER BİLGİLER</span>
                            <textarea required onChange={handleInputChange} name="otherInfo" value={formData.otherInfo} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>CV'NİZİ YÜKLEYİN</span>
                            <input type="file" required onChange={handleInputChange} name="cv" value={formData.cv} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>

                    </div>
                    <div className="mb-4">
                        <ReCAPTCHA
                            sitekey="6LfRLU0pAAAAACQH3vJsHAXPpm5h7s2-LQsdyjQw"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    <button className='bg-primary px-10 py-4 hover:bg-primary/50 duration-300 text-white' onClick={RegisterAction}>
                        <span className=''>GÖNDER</span>
                    </button>
                    {responseMessage && (
                        <div className='text-white'>
                            {responseMessage.message}
                        </div>
                    )}
                </div>
            </div>
            <Band />
            <br /><br /><br /><br />
        </div>
    );
}

export default RegisterIK;

import React, { useEffect, useState } from 'react'
import { H3Text, Spacing } from './ComponentExport';
import Logo from './common/Logo';
import ProgramLogo from '../assets/image/program-logo.png'
import AxiosHelper from '../services/AxiosHelper';

const Program = ({ data, name = "ANTALYA", hideLogos }) => {
    const api = AxiosHelper();

    const [logos, setLogos] = useState();
    useEffect(() => {
        api.get('?table_name=collaborations').then((res) => {
            setLogos(res);
        })
    }, [])
    const daysOfWeek = ["PAZARTESİ", "SALI", "ÇARŞAMBA", "PERŞEMBE", "CUMA", "CUMARTESİ", "PAZAR"];
    return (
        <div>
            <div className='flex justify-center'>
                <div className='text-white grid grid-cols-10 py-12'>
                    <div className='col-span-1 relative max-sm:hidden'>
                        <span className='outlined-text rounded-[20px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-90 tracking-[50px] uppercase -ml-32'>
                            {name}
                        </span>
                    </div>
                    <div className='col-span-9 max-sm:col-span-11'>
                        <div className='flex justify-between mb-8 max-sm:block max-sm:text-center'>
                            <div className='flex flex-col items-center'>
                                <Logo />
                                <div className='text-3xl font-medium'>DERS PROGRAMI</div>
                            </div>
                            <div className='flex flex-col justify-end text-zinc-400/80 font-medium'>
                                <div>Gymfactory’e hoş geldiniz!</div>
                                <div>Gerçek sağlıklı yaşam,;zihni, bedeni ve ruhu kapsar.</div>
                            </div>
                        </div>
                        <div className='max-sm:overflow-x-scroll'>
                            <div className="">
                                {data &&
                                    data.reduce((uniqueDays, currentItem) => {
                                        if (!uniqueDays.includes(currentItem.gun)) {
                                            uniqueDays.push(currentItem.gun);
                                        }
                                        return uniqueDays;
                                    }, []).map((day, dayIndex) => (
                                        <div key={dayIndex} className="p-4 m-2 flex">
                                            <div className=" border-primary border text-xs">
                                               <div className='font-medium w-28 text-center p-4 flex items-center justify-center h-full'>{day}</div>
                                            </div>
                                            <div className="flex w-full max-sm:w-min justify-between flex-no-wrap">
                                                {data
                                                    .filter(item => item.gun.toLowerCase() === day.toLowerCase())
                                                    .map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className={`border text-center font-medium border-gray-300/20 text-xs flex flex-col justify-center hover:bg-primary p-4 mx-2 w-max relative ${index % 2 === 0 ? 'bg-zinc-800' : 'bg-black'}`}
                                                         
                                                        >
                                                            {item.ders.split('\n').map((line, lineIndex) => (
                                                                <div key={lineIndex}>{line}</div>
                                                            ))}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>

                                    ))}
                            </div>


                        </div>
                        <div className='outlined-text-grey opacity-30 max-sm:scale-50'>PARTNERS</div>
                    </div>
                </div>
            </div>
            <Spacing mh={30} />
            {!hideLogos && <div>
                <div className='flex justify-end'>
                    <div className='flex max-sm:block bg-primary p-4 py-12 px-12 max-sm:py-4 max-w-max'>
                        {logos && logos.map((val, key) => {
                            return (
                                <img className='mr-4 max-sm:my-4 w-8' src={`https://batuhankaan.com/musteri/gym-factory//${val.logo}`} />
                            )
                        })}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Program